import Swiper from "swiper/bundle";
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper/core";
SwiperCore.use([Navigation, Pagination, Scrollbar]);

const blogSlider = new Swiper(".blogSlider.swiper-container", {
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	pagination: {
		el: ".swiper-pagination",
		type: "bullets",
	},
	loop: true,
	centeredSlides: true,
	autoplay: {
		delay: 3000,
	},
	autoHeight: false,
	grabCursor: true,
	breakpoints: {
		1200: {
			slidesPerView: 4.5,
			spaceBetween: 60,
		},
		992: {
			slidesPerView: 3.5,
			spaceBetween: 60,
		},
		768: {
			slidesPerView: 2.5,
			spaceBetween: 50,
		},
		640: {
			slidesPerView: 2,
			spaceBetween: 50,
		},
		320: {
			slidesPerView: 1.4,
			spaceBetween: 20,
		},
	},
});
