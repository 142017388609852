import ScrollReveal from "scrollreveal";

const delay = 350;

ScrollReveal().reveal(".slow-bottom", {
	delay: delay,
	duration: 1000,
	distance: "15px",
	origin: "bottom",
});

ScrollReveal().reveal(".sr-bottom", {
	delay: delay,
	duration: 1000,
	distance: "15px",
	origin: "bottom",
});

ScrollReveal().reveal(".sr-right", {
	delay: delay,
	duration: 1000,
	distance: "15px",
	origin: "right",
});

ScrollReveal().reveal(".sr-left", {
	delay: delay,
	duration: 1000,
	distance: "15px",
	origin: "left",
});

ScrollReveal().reveal(".page-header h2,.page-header h5", {
	delay: delay,
	duration: 1000,
	distance: "15px",
	origin: "bottom",
});

ScrollReveal().reveal(".table__item", {
	delay: delay,
	duration: 1000,
	distance: "15px",
	origin: "bottom",
});
