import "jquery-drawer";

$(".drawer").drawer({
	iscroll: {
		mouseWheel: false,
	},
});

$(".drawer__hover").hover(
	function () {
		$(".drawer__hover-child").addClass("show");
	},
	function () {
		$(".drawer__hover-child").removeClass("show");
	}
);

const heroHeight = $(".hero").height() - 200;
const childHeroHeight = $(".hero-child").height() - 200;

$(window).scroll(function () {
	const top = $(window).scrollTop();
	if (heroHeight < top || childHeroHeight < top) {
		$(".drawer__bar").addClass("bgShow");
	} else {
		$(".drawer__bar").removeClass("bgShow");
	}
});

if (window.matchMedia("(max-width:1199px)").matches) {
	// $(".drawer__hover-child").hide();
	$(".drawer__hover").on("click", function () {
		$(this).children("ul").slideToggle();
	});
}
