$(".anchor__wrap").hover(
	function () {
		$(this).find(".anchor__bg").addClass("zoom");
	},
	function () {
		$(this).find(".anchor__bg").removeClass("zoom");
	}
);

if ($("#serviceOne")) {
	$("#serviceOne").hover(
		function () {
			$("#serviceImgOne").addClass("zoom");
		},
		function () {
			$("#serviceImgOne").removeClass("zoom");
		}
	);
}

if ($("#serviceTwo")) {
	$("#serviceTwo").hover(
		function () {
			$("#serviceImgTwo").addClass("zoom");
		},
		function () {
			$("#serviceImgTwo").removeClass("zoom");
		}
	);
}

if ($("#serviceThree")) {
	$("#serviceThree").hover(
		function () {
			$("#serviceImgThree").addClass("zoom");
		},
		function () {
			$("#serviceImgThree").removeClass("zoom");
		}
	);
}
