const changeHeight = () => {
	const aboutMain = document.getElementById("aboutMain");
	const aboutImg = document.getElementById("aboutImg");
	const aboutBanner = document.getElementById("aboutBanner");
	if (aboutMain) {
		const aboutMainHeight = aboutMain.offsetHeight / 2;

		aboutImg.style.height = aboutMainHeight + "px";
		aboutBanner.style.height = aboutMainHeight + "px";
	}
};

changeHeight();
window.addEventListener("resize", changeHeight);
